import client from "../../../assets/service/client";
import { useQuery, useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";

import {
    Container, 
    ListWrapper,
    Wrapper,
    Informative,
    TicketInfo,
    Description,
    Price,
    Button,
    Config,
    Item,
    ButtonWrapper,
    ConfirmationWrapper,
    ButtonLink,
} from './styles'

import { useState } from "react";
import Loader from "../../../assets/components/loader/Loader";

const excludedList = [
     935
    ,956
    ,957
    ,965
    ,966
    ,973
    ,1003
    ,1004
    ,1005
    ,1007
    ,1008
    ,1009
    ,1010
    ,1017
    ,1023
    ,1024
    ,1029
    ,1030
    ,1031
    ,1032
    ,1043
    ,1045
    ,1047
    ,1048
    ,1049
    ,1050
    ,1051
    ,1052
    ,1053
    ,1054
    ,1062
    ,1063
    ,1064
    ,1065
    ,1066
    ,1067
    ,1068
    ,1069
    ,1070
    ,1071
    ,1072
    ,1073
    ,1074
    ,1075
    ,1076
    ,1077
    ,1078
    ,1079
    ,1080
    ,1082
    ,1083
    ,1084
    ,1085
    ,1086
    ,1087
]

const Control = () => {
    const { id } = useParams();
    const [activeTicketSpecs, setActiveTicketSpecs] = useState([])

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            let currentActiveTicketSpecs = [];
            for (let t of res.data.ticketSpecs) {
                if (t.active) currentActiveTicketSpecs.push(parseInt(t.id))
            }
            setActiveTicketSpecs(currentActiveTicketSpecs);
            return res.data.ticketSpecs;
        })
        .catch(err => err)
    }

    const setBatches = async ({eventId, ticketSpecIds}) => {
        return await client.post(`/ticket/active`, {
            eventId,
            ticketSpecIds
        })
        .then(res => {
            return res.data;
        })
    }

    const checked = (event) => {
        const ticketSpecId = parseInt(event.target.value);
        const { checked } = event.target;
        if (checked) {
            setActiveTicketSpecs([...activeTicketSpecs, ticketSpecId])
        } else {
            setActiveTicketSpecs(activeTicketSpecs.filter(tid => tid != ticketSpecId))
        }
    }

    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);
    const {isIdle, isLoading, isError, isSuccess, mutate} = useMutation(setBatches);

    return (
        <Container>
            <Config>
                <Item active={true} onClick={ () => {}}>Lotes</Item>
                {/* <Item active={false} onClick={ () => {} }>Promos</Item> */}
            </Config>
            <ListWrapper>
                {
                    ticketSpecs && ticketSpecs.filter(t => !excludedList.includes(t.id)).sort((a, b) => a.description.localeCompare(b.description)).map(t => {
                        return (
                            <Wrapper key={t.id}>
                                <Informative>
                                    <TicketInfo to={`/events/${id}/control/update/${t.id}`} state={{ ticket: t}}>
                                        <Description>{t.description}</Description>
                                        <Price>R${t.price}</Price>
                                    </TicketInfo>
                                    {
                                    t.promo ? <span style={{fontSize: "0.75em", color: "gray"}}>cód. promo: <b>{t.promo}</b></span> :
                                    <input type="checkbox" checked={activeTicketSpecs.includes(parseInt(t.id))} value={t.id} onChange={(event) => checked(event)}></input>
                                    }
                                </Informative>
                            </Wrapper>
                        )
                    })
                }
            </ListWrapper>
            {
            isIdle ? 
            <ButtonWrapper>
                <ButtonLink to={`/events/${id}/control/new`}><span style={{fontSize: '2em', fontWeight: "bold"}}>+</span></ButtonLink>
                <Button onClick={() => mutate({eventId: id, ticketSpecIds: activeTicketSpecs})}>Salvar</Button>
            </ButtonWrapper> :
            isLoading ? <Loader scale={0.4} /> :
            isError ? <>algo deu errado</> :
            isSuccess && 
            <ConfirmationWrapper>
                <span>Lotes atualizados com sucesso</span> 
                <Link to={`/events/${id}`}>Voltar</Link>
            </ConfirmationWrapper>
            }
        </Container>
    )

}

export default Control