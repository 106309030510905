import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { 
    ItemWrapper, 
    Label, 
    Input, 
    Container, 
    Button,
    Wrapper,
    Informative,
    TicketInfo,
    Description,
    Price, 
    ListWrapper
} from "./styles"
import client from "../../../../assets/service/client";
import { useQuery } from "react-query";

import { Bars } from "react-loader-spinner";

const excludedList = [
    935
   ,956
   ,957
   ,965
   ,966
   ,973
   ,1003
   ,1004
   ,1005
   ,1007
   ,1008
   ,1009
   ,1010
   ,1017
   ,1023
   ,1024
   ,1029
   ,1030
   ,1031
   ,1032
   ,1043
   ,1045
   ,1047
   ,1048
   ,1049
   ,1050
   ,1051
   ,1052
   ,1053
   ,1054
   ,1062
   ,1063
   ,1064
   ,1065
   ,1066
   ,1067
   ,1068
   ,1069
   ,1070
   ,1071
   ,1072
   ,1073
   ,1074
   ,1075
   ,1076
   ,1077
   ,1078
   ,1079
   ,1080
   ,1082
   ,1083
   ,1084
   ,1085
   ,1086
   ,1087
]

const CreatePromoForm = () => {
    const { id } = useParams();
    const [code, setCode] = useState("");
    const [limit, setLimit] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [activeTicketSpecs, setActiveTicketSpecs] = useState([])

    const onSubmit = async () => {
        if (!code) return;
        setLoading(true);

        await client.post("promo/new", {
            eventId: id,
            code: code.toLowerCase(),
            limit,
            ticketSpecIds: activeTicketSpecs
        })
        navigate(-1)
    }

    const checked = (event) => {
        const ticketSpecId = parseInt(event.target.value);
        const { checked } = event.target;
        if (checked) {
            setActiveTicketSpecs([...activeTicketSpecs, ticketSpecId])
        } else {
            setActiveTicketSpecs(activeTicketSpecs.filter(tid => tid != ticketSpecId))
        }
    }

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            return res.data.ticketSpecs;
        })
        .catch(err => err)
    }

    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);

    return (
        <Container>
            <ItemWrapper>
                <Label>Código*</Label>
                <Input value={code} onChange={(event) => setCode(event.target.value.toLowerCase())} />
            </ItemWrapper>
            <ItemWrapper>
                <Label>Limite</Label>
                <Input value={limit ? limit : ""} onChange={(event) => setLimit(event.target.value)} />
            </ItemWrapper>
            <ListWrapper>
            {
            ticketSpecs && ticketSpecs.filter(t => !excludedList.includes(t.id)).sort((a, b) => a.description.localeCompare(b.description)).map(t => {
                    return (
                        <Wrapper key={t.id}>
                            <Informative>
                                <TicketInfo>
                                    <Description>{t.description}</Description>
                                    <Price>R${t.price}</Price>
                                </TicketInfo>
                                {
                                t.promo ? <span style={{fontSize: "0.75em", color: "gray"}}>cód. promo: <b>{t.promo}</b></span> :
                                <input type="checkbox" checked={activeTicketSpecs.includes(parseInt(t.id))} value={t.id} onChange={(event) => checked(event)}></input>
                                }
                            </Informative>
                        </Wrapper>
                    )
                })
            }
            <div style={{minHeight: '60px', height: 'calc(8% + 10px)'}} />
            </ListWrapper>
            { !loading ? <Button onClick={onSubmit}>Salvar</Button> : <Button><Bars
                                        height="30"
                                        width="30"
                                        color="white"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{width: "100%", display: 'flex', 'alignItems': 'center', 'justifyContent': 'center'}}
                                        wrapperClass=""
                                        visible={true}
                                    /></Button>
        }
        </Container>
        )
}

export default CreatePromoForm