import { ChartContainer, AnalyticsContainer, DownloadIcon } from "./styles";
import { Bar, BarChart, CartesianGrid, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { useQuery } from "react-query";
import client from "../../../assets/service/client";
import { useParams } from "react-router-dom";
import { AiOutlineCloudDownload } from "react-icons/ai";

const Analytics = () => {
    const { id } = useParams();
    const getTicketsByEventId = async () => {
        return await client.get(`/event/${id}/mintedTickets`)
        .then(res => {
            return res.data.tickets;
        })
        .catch(err => err)
    }

    const getTicketSpecsByEventId = async () => {
        return await client.get(`/event/${id}/ticketSpecs`)
        .then(res => {
            return res.data.ticketSpecs;
        })
        .catch(err => err)
    }

    const getPaymentsByEventId = async () => {
        return await client.get(`/event/${id}/payments`)
        .then(res => {
            return res.data.payments.filter(p => !p.cancelled);
        })
        .catch(err => err)
    }

    const downloadTicketInfo = (data) => {
        let json_parse = data.map(d => {
            return {"name": `${d.user.firstName} ${d.user.lastName}`, "ticket": d.ticketSpec.description, "date": d.createdAt }
        })

        let headers = ["name,ticket,date"]
        let compile = json_parse.reduce((acc, p) => {
            let { name, ticket, date } = p
            acc.push([name, ticket, date].join(','))
            return acc
        }, [])

        const blob = new Blob([ [ ...headers, ...compile ].join('\n') ], { type: "text/csv" });

        const a = document.createElement('a')
        a.download = 'tickets.csv'
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    let parseByDate = (items, col) => {
        let reducedItems = items.reduce((acc, curr) => {
            let key = new window.Date(curr.createdAt).toDateString();
            let increment = parseFloat(col ? curr[col] : 1)
            acc[key] = acc[key] ? acc[key] + increment : increment;
            return acc
        }, {})

        let parsedItems = [];

        for (let date in reducedItems) {
            parsedItems.push({
                date,
                value: reducedItems[date]
            })
        }

        parsedItems.sort(function(a, b) { return new Date(a.date) - new Date(b.date) });

        for (let item of parsedItems) {
            item.date = new window.Date(item.date).toLocaleDateString('pt-BR');
        }
        
        return parsedItems
    }

    let parseBySpec = (items, specs) => {
        let reducedItems = items.reduce((acc, curr) => {
            let key = curr.ticketSpecId;
            acc[key] = acc[key] ? acc[key] + 1 : 1;
            return acc
        }, {})

        let parsedItems = [];

        for (let spec in reducedItems) {
            parsedItems.push({
                spec,
                value: reducedItems[spec]
            })
        }

        for (let item of parsedItems) {
            item.spec = specs.filter(ts => ts.id == item.spec)[0]?.promo || specs.filter(ts => ts.id == item.spec)[0]?.description
        }
        
        return parsedItems
    }

    let parseByGender = (items) => {
        let reducedItems = items.reduce((acc, curr) => {
            let key = curr.user.gender;
            acc[key] = acc[key] ? acc[key] + 1 : 1;
            return acc
        }, {})

        let parsedItems = [];

        for (let gender in reducedItems) {
            parsedItems.push({
                gender,
                value: reducedItems[gender]
            })
        }
        
        return parsedItems
    }

    const { data: ticketSpecs } = useQuery('ticketSpecsByEventId', getTicketSpecsByEventId);
    const { data: tickets, refetch: refetchTickets } = useQuery('ticketsByEventId', getTicketsByEventId);
    const { isLoading, isError, data: payments } = useQuery('paymentsByEventId', getPaymentsByEventId);

    return (
        <AnalyticsContainer>
        { tickets && <DownloadIcon onClick={() => downloadTicketInfo(tickets)}><AiOutlineCloudDownload style={{fontSize: "2em"}} /></DownloadIcon> }
        <ChartContainer style={{minHeight: "30%"}}>
        <span><b># Ingressos</b></span>
        {tickets && <h1>{tickets.filter(t => !t.cancelled).filter(t => t.ticketSpecId != 627).length}</h1> }
        </ChartContainer>
        <ChartContainer style={{minHeight: "30%"}}>
        <span><b>Ticket Médio</b></span>
        { payments && tickets && <h1>{
            (payments.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) / tickets.filter(t => !t.cancelled && ![1148, 1147, 1146, 1145, 1144, 1143, 1142, 1141].includes(t.ticketSpecId)).length).toFixed(2)}
        </h1> }
        </ChartContainer>
        <ChartContainer>
        <span><b># Vendas</b></span>
        {
        tickets && <ResponsiveContainer height='85%' width='100%'>
            <BarChart data={parseByDate(tickets)} title="Vendas">
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis dataKey="date"></XAxis>
                <Tooltip />
                <Bar type="monotone" dataKey="value" fill="#470A68" />
            </BarChart>
        </ResponsiveContainer>
        }
        </ChartContainer>
        <ChartContainer>
            <span><b>Faturamento por Dia</b></span>
            {
            payments && <ResponsiveContainer height='85%' width='100%'>
                <BarChart data={parseByDate(payments, 'amount')} title="Pagamentos">
                    <XAxis dataKey="date"></XAxis>
                    <Tooltip />
                    <Bar type="monotone" dataKey="value" fill="#470A68" />
                </BarChart>
            </ResponsiveContainer>
            }
        </ChartContainer>
        <ChartContainer>
            <span><b>Vendas por Lote</b></span>
            {
            tickets && ticketSpecs && <ResponsiveContainer height='85%' width='100%'>
                <BarChart data={parseBySpec(tickets, ticketSpecs)} title="Lotes">
                    <XAxis dataKey="spec"></XAxis>
                    <Tooltip />
                    <Bar type="monotone" dataKey="value" fill="#470A68" />
                </BarChart>
            </ResponsiveContainer>
            }
        </ChartContainer>
        <ChartContainer style={{minHeight: "30%"}}>
        <span><b>Validações</b></span>
        { tickets && <h1>{ tickets.reduce((acc, curr) => { return acc + (curr.validated ? 1 : 0) }, 0) }</h1> }
        </ChartContainer>
        {/* <ChartContainer>
            <span><b>Vendas por Lote</b></span>
            {
            tickets && ticketSpecs && <ResponsiveContainer height='85%' width='100%'>
                <PieChart width={400} height={400}>
                    <Pie data={parseBySpec(tickets, ticketSpecs)} dataKey="value" nameKey="spec" cx="50%" cy="50%" outerRadius={80} fill="#470A68" />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            }
        </ChartContainer> */}
        {/* <ChartContainer>
            <span><b>Ingressos por Gênero</b></span>
            {
            tickets && ticketSpecs && <ResponsiveContainer height='85%' width='100%'>
                <PieChart width={400} height={400}>
                    <Pie data={parseByGender(tickets)} dataKey="value" nameKey="gender" cx="50%" cy="50%" outerRadius={80} fill="#470A68" />
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            }
        </ChartContainer> */}
        </AnalyticsContainer>
    )
}

export default Analytics